@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;0,700;1,500&display=swap);
.App {
  
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f6f8;
    color: #454647;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a:hover {
    color: #6f952d;
}

.secondary {
    color: #6f952d;
}

h1 {
    margin-bottom: 20px;
    font-weight: 700;
    color: #3c6838;
}

h3 {
    font-size: 1.3em;
    font-weight: 400;
    color: #454647;
}

.lightfont {
    font-weight: lighter;
}


/* btn-primary*/

.btn-primary {
    box-shadow: -1px 3px 7px 0px #00000052;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active {
    font-weight: 700;
    color: #fff;
    background-color: #3a6e34;
    border-color: #3a6e34;
    border-radius: 0;
    min-width: 160px;
    min-height: 50px;
    transition: all 0.25s ease-in;
}

.btn-primary:hover {
    color: #fff;
    background-color: #6f952d;
    border-color: #6f952d;
    border-radius: 0;
    min-width: 160px;
    min-height: 50px;
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
    background-color: #3a6e34;
    border-color: #3a6e34;
    opacity: 0.5;
    box-shadow: none;
}


/* btn-primaryEnd*/

/* btn-danger*/
button.btn.btn-danger {
    height: 60px;
    background-color: #D5484E;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
}



/* btn-primary SM little*/



.btn-primary.btn-sm,
.btn-primary.btn-sm:focus,
.btn-primary.btn-sm:not(:disabled):not(.disabled).active,
.btn-primary.btn-sm:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-sm:not(:disabled):not(.disabled):active {
    background-color: #789F00;
    border-color: #789F00;
    min-width: 50px;
    min-height: 20px;
    font-size: 12px;
}

.btn-primary.btn-sm:hover {
       background-color: #3a6e34;
    border-color: #3a6e34;
    min-width: 50px;
    min-height: 20px;
      font-size: 12px;
}


/* btn-primarySM little End */






/* btn-secondary*/

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
    font-weight: 500;
    color: #fff;
    background-color: #6f952d;
    border-color: #6f952d;
    border-radius: 0;
    min-width: 120px;
    min-height: 30px;
    box-shadow: -1px 3px 7px 0px #00000052;
    transition: all 0.25s ease-in;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #3a6e34;
    border-color: #3a6e34;
    border-radius: 0;
    min-width: 120px;
    min-height: 30px;
    box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    background-color: #6f952d;
    border-color: #6f952d;
    opacity: 0.5;
}


/* btn-secondary END*/


/* btn-light*/

.btn-light,
.btn-light:focus,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled):active {
    font-weight: 500;
    background-color: #fff;
    color: #70952d;
    border-color: #fff;
    border-radius: 0;
    min-width: 120px;
    min-height: 30px;
    box-shadow: -1px 3px 7px 0px #00000052;
    transition: all 0.25s ease-in;
}

.btn-light:hover {
    color: #fff;
    background-color: #3a6e34;
    border-color: #3a6e34;
    border-radius: 0;
    min-width: 120px;
    min-height: 30px;
    box-shadow: none;
}

.btn-light.disabled,
.btn-light:disabled,
.btn-light.disabled:hover,
.btn-light:disabled:hover {
    background-color: #6f952d;
    border-color: #6f952d;
    opacity: 0.5;
}


/* btn-btn-light END*/


/* FORM INPUT*/

.form-control {
    background-color: transparent;
    padding: 1.5em 0.75rem;
}

.form-control:focus {
    background-color: transparent;
    border: 1px solid #ced4da;
    box-shadow: 0 0 0 0.2rem rgb(151 195 73 / 60%);
}

.input-group.inputwithappend .form-control {
    border-right: none;
}

.input-group.inputwithappend .btn-outline-secondary,
.input-group.inputwithappend .btn-outline-secondary:focus,
.input-group.inputwithappend .btn-outline-secondary:not(:disabled):not(.disabled).active,
.input-group.inputwithappend .btn-outline-secondary:not(:disabled):not(.disabled):active {
    font-weight: 700;
    border: 1px solid #ced4da;
    border-left: none;
    color: #3a6e34;
    background-color: transparent;
    box-shadow: none;
}

.input-group.inputwithappend .btn-outline-secondary:hover {
    border: 1px solid #ced4da;
    color: #adc36b;
    background-color: transparent;
    box-shadow: none;
    border-left: none;
}

.logonowaste {
    width: 260px;
     max-width: 360px;
}


/* Switch Login*/

.switch-login {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 30px auto 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    box-shadow: inset 0px -3px 0px 0px rgb(228 228 228);
}

.switch-login p {
    margin: 0;
    color: #adc36b;
    font-weight: 700;
    border-bottom: 2px solid #adc36b;
    padding: 0;
    float: left;
    text-align: right;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 0.1s 0s ease-in;
}

.switch-login a {
    margin: 0;
    text-decoration: none;
    color: #adc36b;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    float: left;
    text-align: left;
    transition: all 0.1s 0s ease-in;
}

.switch-login *:first-child {
    margin: 0 20px 0 0;
}

.switch-login a:hover {
    text-decoration: none;
    color: #adc36b;
    font-weight: 700;
    border-bottom: 2px solid #adc36b;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    float: left;
    text-align: left;
    transition: all 0.1s 0s ease-in;
}

.switch-login:hover p {
    border-bottom: transparent;
    transition: all 0.1s 0s ease-in;
}

.switch-login p:hover,
.switch-login a:hover~a {
    font-weight: 700;
    border-bottom: 2px solid #adc36b;
    transition: all 0.1s 0s ease-in;
}


/* Switch Login End*/

.margin-top {
    margin-top: 30px;
}


/* Validation */

.custom-file-input:focus~.custom-file-label.error,
.input-group>.custom-select:focus.error,
.input-group>.form-control:focus.error,
#Autentication .form-control.error,
.error {
    color: #d8585d;
    background-color: #efd0d3;
}


/* Validation End*/


/* Info Password Toast */

.alert_container {
    margin: auto;
}

.icontips_container p,
.icontips_container img {
    display: inline;
}

.alert-success {
    color: #363636;
    background-color: #d1e2a4;
    border: none;
    font-size: 12px;
}

.iconinfo {
    border-radius: 100px;
    height: 30px;
    cursor: pointer;
    align-self: center;
    margin: 5px;
    transition: all 0.2s 0s ease-in;
}

.iconinfo:hover {
    background-color: #6f962b;
    height: 34px;
    align-self: center;
    margin: 3px;
    border-radius: 100px;
    box-shadow: 0 0 9px 2px rgb(212 237 218);
}

.rowiconinfo {
    display: flex;
    flex-wrap: wrap;
}

.rowiconinfo .col-1:last-child {
    align-self: center;
    text-align: left;
    margin-left: 0;
    padding: 0;
    margin-top: 5px;
}


/*Info Password Toast */


/* Allergenes */

#Allergenes input[type="checkbox"] {
    display: none;
}

#Allergenes label {
    color: #585858;
    cursor: pointer;
    align-self: center;
    margin-bottom: 0;
}

#Allergenes img {
    width: 36px;
    background-color: #585858;
}

#Allergenes input[type="checkbox"]:checked~label {
    color: #6f962b;
}

#Allergenes input[type="checkbox"]:checked + img {
    background-color: #6f962b;
}

.allergenes_checkbox {
    text-align: left;
    display: flex;
    place-items: center;
    min-height: 47px;
}


/* Customize  CHECKBOX NO ALLERGY */


/* Customize the label (the container) */

.container_checkbox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 30px auto;
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.container_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 100px;
    border: 2px solid #585858;
    background-color: #eee;
}


/* On mouse-over, add a grey background color */

.container_checkbox :hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container_checkbox input:checked~.checkmark {
    background-color: #70952d;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container_checkbox input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container_checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


/* Customize  CHECKBOX NO ALLERGY END */


/* Autentication Input  */

form.center-form {
    margin-left: 8.888888%;
}

#Autentication {
    display: flex;
    justify-content: center;
}

#Autentication .form-control {
    float: left;
    margin: 40px 8px;
    height: 80px;
    background-color: transparent;
    padding: 1.5em 0.75rem;
    min-width: 12%;
    max-width: 70px;
    text-align: center;
}

#invalid_code {
    margin-top: 60px;
    display: none;
}

#invalid_code.showerror {
    display: block;
}


/* Autentication Input  END*/


/* HOME*/

#Modal_ordersend .logonowaste {
    margin-bottom: 60px;
}

#No_doggy {
    height: 100%;
    background: url(/static/media/bg_no_item.41b14e40.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.doggy_square {
    background-color: #b6cf8c;
    color: #fff;
    padding: 30px;
    text-align: center;
}

.doggy_left .btn-secondary {
    margin: 20px auto;
    background-color: #b6cf8c;
    border: none;
}

.labels_container {
    flex: 0 0 180px;
    align-self: center;
    text-align: start;
}

.image_label {
    border-radius: 100%;
    width: 40px;
}

.calories {
    flex: 0 0 100px;
    align-self: center;
    text-align: end;
}

.calories p {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.flex-item-100 {
    flex: 1 0 100%;
}

.home_icon_allergenes {
    width: 30px;
    background-color: #585858;
}

.md_100 {
    margin: 10px auto;
    flex: 1 1;
}

.md_100 p {
    margin: 0;
}

.img_doggy {
    max-width: 90px;
    margin: 30px auto;
}

.buttons_doggymodal {
    text-align: center;
}

.buttons_doggymodal .btn {
    margin: 5px 15px;
}

.buttons_doggymodal .btn-secondary {
    background-color: #b6cf8c;
    border: none;
}

/* Carousel */

.restaurant_container {
    padding: 20px 10px;
    background-color: #70952d;
    margin: 0 10px;
    min-height: 100%;
}

.carousel_restaurants .restaurant_container {
    min-height: 100%;
}

.restaurant_container .iconrestaurant {
    margin: auto;
    text-align: center;
    display: flex;
    padding: 10px;
    margin: 30px auto;
    max-height: 120px;
}

.carousel .slider .slide {
    background-color: transparent;
}

.restaurant_container h3 {
    text-align: center;
    margin: 5px 20px;
    font-weight: 700;
    color: #fff;
}

.restaurant_container p {
    text-align: center;
    margin: 5px 20px;
    font-weight: 400;
    color: #fff;
}


/* Carousel END*/


/* Sistemare */

.top_80 {
    margin-top: 80px;
}

h2.modal_title {
    margin: 30px auto;
    text-align: center;
    font-weight: 700;
    color: #70952d;
}

.ReactModal__Overlay {
    z-index: 100;
    background-color: rgb(72 72 72 / 75%) !important;
}

.ReactModal__Content.ReactModal__Content {
    border: none !important;
    background: #f5f6f8 !important;
    overflow: auto !important;
    border-radius: 0 !important;
    height: 80% !important;
    max-height: 85% !important;
    min-width: auto !important;
    max-width: 960px !important;
    margin:auto;
}

.ReactModal__Content.ReactModal__Content.fullModal {
 
    height: 1000% !important;
    max-height:100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin:0;
}

.ReactModal__Content.ReactModal__Content.lowModal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    width: 100%;
    position:absolute;
    transform: translate(-50%, -50%);
    border: none !important;
    background: #f5f6f8 !important;
    overflow: auto !important;
    border-radius: 0 !important;
    height: auto !important;
    max-height: 85% !important;
    min-width: auto !important;
    max-width: 960px !important;
    margin:auto;
}

.row_modal {
    margin: 0;
}

.padding-bottom {
    padding-bottom: 70px;
}

.ReactModal__Content .restaurant_container {
    min-height: 250px;
    max-width: 250px;
    margin: auto;
}

.ReactModal__Content .button_container {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding:10px;
  margin: auto;
  text-align: center;
}

.ReactModal__Content .button_container button{
  margin: 10px;
}

.switch-container {
    margin-top: 70px;
    height: 50px;
}

@media (max-width: 767.98px) {
    .md_100 {
        flex: 0 0 100%;
    }
}

#outside-container.hide {
    display: none;
}

.personalarea_container {
    position: absolute;
    z-index: 1000;
    background-color: #f5f5f5;
    right: 20px;
    top: 50px;
    border: 1px solid #789F00;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 1px #0000003b;
}

.personalarea_container h3 {
    border-bottom: 1px solid #789F00;
    color: #789F00;
    font-size: 14px;
    text-align: left;
    padding: 5px 15px;
    font-weight: 800;
    margin-bottom: 3px;

}

.personalarea_container ul {
    text-align: left;
    padding:5px 0;
    margin: 0;
}

.personalarea_container li {
    font-size: 16px;
    color: #363636;
    padding: 3px 15px;
    list-style: none;
    text-align: left;
    font-weight: 400;
    cursor: pointer;
}

.personalarea_container li:hover {
    color: #789F00;
}

.personalarea_container li.red{
    color: #B5484E;
}

.personalarea_container li.red:hover{
    color: #d6000b;
}

.personalarea_container li .svg-inline--fa {
    width: 0.9em;
}

.cancel p{
    color: #32323266;
    cursor: pointer;
    float: right;
}

.cancel p:hover{
    color: #323232;
}

.alert-danger {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    background-color: #D5484E;
    border-color: #f5c6cb;
    font-style: italic;}



.myRestaurantText span{
    font-weight: bold;
    font-size: 16px;
    vertical-align: bottom;
}

.myRestaurantText svg{
    color: #789F00;
    vertical-align: middle;
}

.iconUser{
    font-size: 18px;
    color: #789F00;
    vertical-align: text-top;
}

.deleteModalTxt {
    color: #363636;
    font-size: 21px;
    text-align: center;
}

.confirmDelete{
    font-weight: 900;
    color: #D5484E;
    text-decoration: underline;
    font-size:16x;
    text-align: center;
    cursor: pointer;
}

.confirmDelete:hover{
    color: #ff000d;
}
    
.modalSuccesPassword{
    background-color: #ededed;
    background-image:url(/static/media/bgSuccessPasswordbg.d5ce42b8.jpg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
}

.modalSuccesDelete{
    background-color: #f6f6f8;
    background-image:url(/static/media/bgDeleteAccount.bd776809.jpg);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
}

.modalSuccesPassword h3, .modalSuccesDelete h3{
    color:#363636;
    font-size: 41px;
    font-weight: bold;
}
    
.logoGreenImok{
    width:160px;
}
